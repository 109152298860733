import { Skeleton, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';

import {
  IconTitleDisplay,
  IconTitleDisplayProps,
} from '../../../components/IconTitleDisplay';
import { RowStack } from '../../../components/RowStack';
import { REGEX_COMMIT_SHA_VALIDATION } from '../../../constants';
import { IconPlanet } from '../../../themes/icons';
import { PackageEcosystemIcons } from '../../Package/constants';
import { UIPackageVersionUtils } from '..';

interface PackageVersionMinimal {
  meta: { name: string };
}

interface PackageVersionNameDisplayProps extends IconTitleDisplayProps {
  packageVersion?: PackageVersionMinimal;
  showVersion?: boolean;
  to?: string;
}

/**
 * Display for a Package, or Package version
 */
export const PackageVersionNameDisplay = ({
  packageVersion,
  showVersion = false,
  ...props
}: PackageVersionNameDisplayProps) => {
  const { spacing } = useTheme();
  const { ecosystem, label, version } = useMemo(
    () => UIPackageVersionUtils.parsePackageName(packageVersion?.meta?.name),
    [packageVersion]
  );

  const versionDisplay = useMemo(() => {
    if (!version) return undefined;

    const versionDisplay = REGEX_COMMIT_SHA_VALIDATION.test(version)
      ? version.slice(0, 7)
      : version;

    return (
      <Typography
        color="text.secondary"
        component="span"
        fontWeight={400}
        variant="inherit"
      >
        <RowStack gap="1px">
          <span>@{versionDisplay}</span>
        </RowStack>
      </Typography>
    );
  }, [version]);

  let titleNode = (
    <Typography
      component="span"
      sx={{ wordBreak: 'break-all' }}
      variant="inherit"
    >
      {!label ? <Skeleton width={spacing(30)} /> : label}
    </Typography>
  );

  if (showVersion && versionDisplay) {
    titleNode = (
      <RowStack alignItems="flex-end" gap="1px" sx={{ lineBreak: 'anywhere' }}>
        {titleNode}
        {versionDisplay}
      </RowStack>
    );
  }

  return (
    <IconTitleDisplay
      {...props}
      Icon={
        packageVersion
          ? PackageEcosystemIcons[ecosystem] ?? IconPlanet
          : undefined
      }
      title={titleNode}
    />
  );
};
