declare global {
  interface Window {
    ENDOR?: Record<string, string>;
  }
}

export interface TEnv {
  API_BASE_URL: string;
  EMAIL_ENDOR_SUPPORT: string;
  ENDORCTL_DOCKER_IMAGE: string;
  HUBSPOT_PORTAL_ID?: string;
  URL_AZURE_PAT: string;
  URL_ENDOR_DOCS: string;
  URL_ENDOR_MARKETING: string;
  URL_ENDOR_SALES: string;
  URL_GITHUB_ACTION: string;
  URL_GITHUB_APP: string;
  URL_GITHUB_APP_PRO: string;
  URL_METRICS_IMPORT?: string;
}

const DEFAULT_ENV: TEnv = {
  API_BASE_URL: 'https://localhost:8443',
  EMAIL_ENDOR_SUPPORT: 'support@endor.ai',
  ENDORCTL_DOCKER_IMAGE: 'us-central1-docker.pkg.dev/endor-ci/public/endorctl',
  URL_AZURE_PAT:
    'https://learn.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate?view=azure-devops&tabs=Windows#create-a-pat',
  URL_ENDOR_DOCS: 'https://docs.endorlabs.com',
  URL_ENDOR_MARKETING: 'https://endorlabs.com',
  URL_ENDOR_SALES: 'https://endorlabs.com/lp/free-trial-contact-us',
  URL_GITHUB_APP: 'https://github.com/apps/endorexample',
  URL_GITHUB_APP_PRO: 'https://github.com/apps/endor-labs-pro-staging',
  URL_GITHUB_ACTION: 'https://github.com/marketplace/actions/endor-labs-scan',
} as const;

export const getEnv = () => {
  // build env from defaults and overrides
  const env = { ...DEFAULT_ENV, ...window.ENDOR };
  // set derived values
  if (!Reflect.has(env, 'URL_METRICS_IMPORT')) {
    env.URL_METRICS_IMPORT = `${env.API_BASE_URL}/ui-metrics`;
  }

  return env;
};
