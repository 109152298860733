import { Box, Skeleton, Stack, Typography } from '@mui/material';

import {
  PackageIconDisplay,
  RowStack,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

export const PackageVersionDetailDrawerHeader = ({
  packageVersionName,
  isLoading,
}: {
  packageVersionName: string;
  isLoading: boolean;
}) => {
  const {
    ecosystem,
    label: packageName,
    version,
  } = UIPackageVersionUtils.parsePackageName(packageVersionName);

  return (
    <RowStack flexWrap="nowrap">
      {isLoading ? (
        <>
          <Skeleton height={40} width={44} />
          <Skeleton height={40} width="60%" />
        </>
      ) : (
        <>
          <PackageIconDisplay
            ecosystem={ecosystem}
            displayDefault="package"
            size="medium"
            withBorder
          />
          <Stack direction="column">
            <Typography component="span" variant="h6">
              {packageName}
            </Typography>

            <Typography component="span" variant="caption">
              {version}
            </Typography>
          </Stack>
        </>
      )}
    </RowStack>
  );
};
