import { Box, Card, IconButton, Stack, Theme } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { get as _get } from 'lodash-es';
import { ReactNode, SyntheticEvent, useCallback, useMemo, useRef } from 'react';

import { useStyles } from '../../hooks';
import { RowStack } from '../RowStack';
import { PAGE_SIZES } from './constants';
import { DataTable, DataTableProps } from './DataTable';
import {
  DataTableActionDropdown,
  DataTableActionDropdownItem,
} from './DataTableActionDropdown';
import { DataTablePagination } from './DataTablePagination';
import { BulkActionRecord, DataTableViewActions } from './DataTableViewActions';
import { DataTableColumnTypeKeys } from './types/DataTableColumnTypes';
import { DataTablePaginator } from './useDataTablePaginator';

export interface DataTableActionItem<RowType>
  extends Omit<DataTableActionDropdownItem, 'onClick'> {
  onClick: (row: RowType) => void;
}

export interface DataTableViewProps<RowType>
  extends Omit<DataTableProps<RowType>, 'onRowClick'> {
  actionsContent?: ReactNode;
  bulkActions?: BulkActionRecord<RowType>[];
  countMessage?: ReactNode;
  filtersContent?: ReactNode;
  isLoading?: boolean;
  namespace: string;
  paginator?: DataTablePaginator;
  rowActions?: DataTableActionItem<RowType>[];
}

export const DataTableView = <RowType,>({
  actionsContent,
  bulkActions = [],
  columns,
  countMessage,
  data = [],
  filtersContent,
  getRowId = (originalRow, index) =>
    (_get(originalRow, 'uuid') as string) ?? `${index}`,
  isLoading = false,
  onRowSelectionChange,
  paginator,
  rowActions = [],
  ...tableOptions
}: DataTableViewProps<RowType>) => {
  const sx = useStyles(styles);

  /**
   * Establish ref to @tanstack/table instance
   * TODO: This winds up not that useful, since components do not-render on a ref change.
   * Instead establish shared contextual table state that includes the table instance.
   */
  const tableRef = useRef<Table<RowType> | null>(null);

  /**
   * Generic callback that delegates to a specific bulk action handler
   */
  const onBulkActionSelect = useCallback(
    (_: SyntheticEvent, actionRecord: BulkActionRecord<RowType>) => {
      if (!actionRecord) return;

      // Retrieve selected rows from @tanstack/table instance
      const affectedRows = actionRecord.isSelectionRequired
        ? tableRef.current?.getSelectedRowModel()
        : tableRef.current?.getRowModel();

      // Call registered callback function with array of selected rows
      actionRecord.onApply(affectedRows?.flatRows.map((r) => r.original) ?? []);
    },
    []
  );

  // If has row actions, augment columns to include these actions
  const finalColumns = useMemo(() => {
    // Add a dropdown if multiple actions
    const rowActionCount = rowActions.length;

    if (rowActionCount === 0) {
      return columns;
    }

    const finalColumns = columns.slice();

    if (rowActionCount > 1) {
      finalColumns.push({
        cell: (t) => {
          // Wrap click handler to operate on row object instead of click event
          const actionItems = rowActions.map((rowAction) => ({
            ...rowAction,
            onClick: (evt: SyntheticEvent) => {
              // Prevent button handler from triggering onRowClick
              evt.stopPropagation();
              rowAction.onClick(t.row.original);
            },
          }));

          return (
            <RowStack justifyContent="flex-end" width="100%">
              <DataTableActionDropdown
                items={actionItems}
                useRawClickHandlers
              />
            </RowStack>
          );
        },
        colType: DataTableColumnTypeKeys.ACTIONS,
        id: 'actions',
      });
    }

    // Add a single icon button for a single action
    if (rowActionCount === 1) {
      const action = rowActions[0];
      const Icon = action.Icon;
      // HACK: allow button title when label is string
      const label = 'string' === typeof action.label ? action.label : undefined;

      finalColumns.push({
        cell: (t) => (
          <RowStack justifyContent="flex-end" width="100%">
            <IconButton
              onClick={(evt) => {
                // Prevent button handler from triggering onRowClick
                evt.stopPropagation();
                action.onClick(t.row.original);
              }}
              title={label}
            >
              {Icon && <Icon />}
            </IconButton>
          </RowStack>
        ),
        colType: DataTableColumnTypeKeys.ACTIONS,
        id: 'actions',
      });
    }

    return finalColumns;
  }, [columns, rowActions]);

  const enableRowSelection = bulkActions.some((ba) => ba.isSelectionRequired);

  /**
   * If provided a "primary" row action, trigger this action on row click
   */
  const primaryRowAction = rowActions.find((action) => action.isPrimaryAction);
  const rowClickHandler = primaryRowAction
    ? primaryRowAction?.onClick
    : undefined;

  return (
    <Box className="DataTableView-root" sx={sx}>
      <Card className="DataTableView-card">
        <Stack className="DataTableView-cardContent">
          {filtersContent && (
            <Box className="DataTableView-filters">{filtersContent}</Box>
          )}

          {Boolean(actionsContent || countMessage || bulkActions.length) && (
            <Box className="DataTableView-actions">
              <DataTableViewActions
                actionDisplay={actionsContent}
                bulkActions={bulkActions}
                countMessage={countMessage}
                onActionSelect={onBulkActionSelect}
              />
            </Box>
          )}

          <Box className="DataTableView-table">
            <DataTable
              {...tableOptions}
              columns={finalColumns}
              data={data}
              enableRowSelection={enableRowSelection}
              onRowSelectionChange={onRowSelectionChange}
              getRowId={getRowId}
              isLoading={isLoading}
              onRowClick={rowClickHandler}
              paginator={paginator}
              ref={tableRef}
              showPagination={false}
            />
          </Box>
        </Stack>
      </Card>

      <Box className="DataTableView-pagination">
        {paginator && (
          <DataTablePagination
            pageSizes={PAGE_SIZES}
            paginator={paginator}
            table={tableRef.current ?? undefined}
          />
        )}
      </Box>
    </Box>
  );
};

function styles({ palette, space, spacing }: Theme) {
  return {
    '&': {},

    '& .DataTableView-card': {
      display: 'grid',
      gridTemplateRows: 'auto',
      marginBottom: space.sm,
      overflowX: 'auto',
      paddingBottom: space.sm,
      paddingTop: space.sm,
      rowGap: space.sm,
    },

    '& .DataTableView-cardContent': {
      // minWidth: `calc(100vw - ${spacing(80)})`,
      overflowX: 'hidden',
    },

    '& .DataTableView-filters, .DataTableView-actions': {
      paddingLeft: space.sm,
      paddingRight: space.sm,
    },

    '& .DataTableView-filters': {
      containerType: 'inline-size',
      marginBottom: space.xs,
    },

    '& .DataTableView-actions': {
      paddingBottom: space.xs,
    },

    '& .DataTableView-table': {
      overflowX: 'auto',

      '& .MuiTableContainer-root': {
        borderBottom: 0,
        borderTop: `1px solid ${palette.divider}`,
      },

      '& .MuiTableRow-root': {
        verticalAlign: 'top',
      },

      '& .MuiTableBody-root .MuiTableRow-root:last-of-type .MuiTableCell-root':
        {
          borderBottom: 0,
        },
    },

    '& .DataTableView-pagination': {
      paddingRight: space.sm,
    },

    '& .DataTableHeader-root': {
      // HACK: support legacy table actions shown in the table header
      padding: spacing(0, 4),
    },
  };
}
