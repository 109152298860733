import { Box, Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  ControlledCheckboxV2,
  ControlledRadioGroup,
} from '@endorlabs/ui-common';

export const PullRequestConfigFields = () => {
  const { space } = useTheme();

  return (
    <Stack rowGap={space.md}>
      <ControlledCheckboxV2
        label={<FM defaultMessage="Enable Automatic Pull Request Scanning" />}
        name="spec.github_config.enable_pr_scans"
      />

      <ControlledCheckboxV2
        label={<FM defaultMessage="Enable Pull Request Comments" />}
        name="spec.github_config.enable_pr_comments"
      />

      <ControlledCheckboxV2
        label={<FM defaultMessage="Include Archived Repositories" />}
        name="spec.github_config.include_archived_repos"
      />

      <Box>
        <ControlledRadioGroup
          label={<FM defaultMessage="Define Scanning Preferences" />}
          name="spec.github_config.enable_full_scan"
          radioRecords={[
            { label: <FM defaultMessage="Quick Scan" />, value: false },
            { label: <FM defaultMessage="Full Scan" />, value: true },
          ]}
        />
      </Box>
    </Stack>
  );
};
