import { pickBy as _pickBy } from 'lodash-es';

import {
  SpecFindingLevel,
  V1FindingCategory,
  V1FindingTags,
  V1GroupResponse,
} from '@endorlabs/api_client';

import {
  FindingRiskMatrixReachability,
  FindingRiskMatrixSeverity,
  FindingRiskMatrixTableRow,
} from './types';

const COLUMN_TESTS: Record<
  FindingRiskMatrixReachability,
  (key: string) => boolean
> = {
  unreachable: (key: string) =>
    key.includes(`"${V1FindingTags.UnreachableFunction}"`),
  reachable: (key: string) =>
    key.includes(`"${V1FindingTags.ReachableFunction}"`),
  potential: (key: string) =>
    !key.includes(`"${V1FindingTags.ReachableFunction}"`) &&
    !key.includes(`"${V1FindingTags.UnreachableFunction}"`),
};

const SEVERITY_LEVELS_TOTAL: Exclude<
  FindingRiskMatrixSeverity,
  SpecFindingLevel
> = 'total';

const SEVERITY_LEVELS: readonly FindingRiskMatrixSeverity[] = [
  SpecFindingLevel.Critical,
  SpecFindingLevel.High,
  SpecFindingLevel.Medium,
  SpecFindingLevel.Low,
  SEVERITY_LEVELS_TOTAL,
];

export const buildFindingRiskMatrixTableRows = (
  findingGroups?: V1GroupResponse['groups']
): FindingRiskMatrixTableRow[] => {
  return Object.entries(findingGroups ?? {}).reduce(
    (acc, [groupKey, groupValues]) => {
      const severity = getSeverity(groupKey) as SpecFindingLevel;
      const colCountToIncrease = getReachability(groupKey);

      const rowToAdjust = acc.find((row) => row.severity === severity);
      const totalsRow = acc.find(
        (row) => row.severity === SEVERITY_LEVELS_TOTAL
      );

      if (
        rowToAdjust !== undefined &&
        totalsRow !== undefined &&
        colCountToIncrease !== undefined
      ) {
        rowToAdjust[colCountToIncrease] +=
          groupValues.aggregation_count?.count ?? 0;
        rowToAdjust[SEVERITY_LEVELS_TOTAL] +=
          groupValues.aggregation_count?.count ?? 0;
        totalsRow[colCountToIncrease] +=
          groupValues.aggregation_count?.count ?? 0;
        totalsRow[SEVERITY_LEVELS_TOTAL] +=
          groupValues.aggregation_count?.count ?? 0;
      }

      return acc;
    },
    genBlankData()
  );
};

const genBlankData = () =>
  SEVERITY_LEVELS.map((severity) => ({
    severity,
    reachable: 0,
    unreachable: 0,
    potential: 0,
    total: 0,
  })) as FindingRiskMatrixTableRow[];

/**
 * Filter grouped Findings down to only matching
 */
export const getFilteredFindingGroups = (
  groupResponseData?: V1GroupResponse['groups'],
  findingCategory?: V1FindingCategory
) => {
  if (!findingCategory) return groupResponseData;

  return _pickBy(groupResponseData, (_, key) =>
    // NOTE: wrapping in quotes to match the JSON serialized value
    key.includes(`"${findingCategory}"`)
  );
};

const getReachability = (
  groupKey: string
): FindingRiskMatrixReachability | undefined => {
  const colEntry = Object.entries(COLUMN_TESTS).find(([_, colTest]) =>
    colTest(groupKey)
  );

  return colEntry ? (colEntry[0] as FindingRiskMatrixReachability) : undefined;
};

const getSeverity = (groupKey: string) => {
  return SEVERITY_LEVELS.find((lvl) => groupKey.match(lvl));
};
