import produce from 'immer';
import {
  cloneDeep as _cloneDeep,
  get as _get,
  isNil as _isNil,
  merge as _merge,
  set as _set,
  uniq as _uniq,
} from 'lodash-es';

import {
  SpecEnabledFeatureType,
  V1Installation,
  V1PlatformSource,
} from '@endorlabs/api_client';
import { InstallationResource } from '@endorlabs/queries';
import { ChecklistInputOption } from '@endorlabs/ui-common';

import { UpsertInstallationFields } from '../components/FormUpsertInstallation';
import { ENABLED_FEATURES_MAP, InstallationDefaultValues } from '../constants';

interface GetInstallationDefaultValuesProps {
  installation?: InstallationResource;
  namespace?: string;
  platformType: V1PlatformSource.Azure | V1PlatformSource.Github;
}

export const getInstallationDefaultValues = ({
  installation,
  namespace,
  platformType,
}: GetInstallationDefaultValuesProps) => {
  // Use provided namespace or look for current namespace
  const installationNamespace =
    namespace ?? installation?.tenant_meta?.namespace ?? '';

  const workingInstallation: V1Installation = installation
    ? _cloneDeep(installation)
    : {
        meta: {
          name: `Installation - ${installationNamespace}`,
        },
        // Uncomment to trigger error
        // meta: {},
        spec: { platform_type: platformType },
        tenant_meta: { namespace: installationNamespace },
      };

  // Set defaults if not present
  const defaultValues = InstallationDefaultValues[platformType];

  for (const propertyName in defaultValues) {
    _set(
      workingInstallation,
      propertyName,
      _get(workingInstallation, propertyName) ?? defaultValues[propertyName]
    );
  }

  // Clear personal_access_token only for Azure platform
  if (platformType === V1PlatformSource.Azure) {
    _set(workingInstallation, 'spec.azure_config.personal_access_token', '');
  }

  return workingInstallation;
};

export const getUpdateMask = (platformType: V1PlatformSource) => {
  switch (platformType) {
    case V1PlatformSource.Github:
      return 'spec.enabled_features,spec.github_config';

    case V1PlatformSource.Azure:
      return 'spec.enabled_features,spec.azure_config';

    default:
      return 'spec.enabled_features';
  }
};

const getPlatformSourceConfigField = (platform: V1PlatformSource) => {
  const defaultConfig = 'github_config';

  switch (platform) {
    case V1PlatformSource.Github:
      return defaultConfig;
    case V1PlatformSource.Azure:
      return 'azure_config';
    default:
      return defaultConfig;
  }
};

export const fieldValuesToInstallationRecord = (
  fieldValues: UpsertInstallationFields,
  originalInstallation?: InstallationResource
): V1Installation => {
  const updatedInstallation: V1Installation = produce(fieldValues, (draft) => {
    // Fields that require conversion to true boolean
    const radioFields = ['spec.github_config.enable_full_scan'];

    const configField = getPlatformSourceConfigField(
      fieldValues.spec?.platform_type ?? V1PlatformSource.Github
    );

    const newConfig = _merge(
      { ...draft.spec?.[configField] },
      fieldValues.spec[configField]
    );

    _set(draft.spec, configField, newConfig);

    radioFields.forEach((radioPath) => {
      const existing = _get(fieldValues, radioPath);

      if (!_isNil(existing)) {
        _set(draft, radioPath, existing === 'true' ? true : false);
      }
    });

    // HACK: Transform full checklist options into simple array
    const enabledFeatures = (fieldValues.spec.enabled_features ??
      []) as unknown as ChecklistInputOption[];

    let updatedEnabledFeatures = enabledFeatures.map((f) => {
      // Check if `f.key` exists and cast it, else return the feature (here contains the key directly)
      // FIXME: Need simplified ControlledChecklist that casts to a string[]
      return f.key
        ? (f.key as SpecEnabledFeatureType)
        : (f as unknown as SpecEnabledFeatureType);
    });

    // Preserve enabled_features not present in field values
    if (originalInstallation) {
      const valuesToPreserve = _get(
        originalInstallation,
        'spec.enabled_features'
      )?.filter(
        (v) =>
          Object.values(ENABLED_FEATURES_MAP)
            .map((feat) => feat.value)
            .includes(v) === false
      );

      updatedEnabledFeatures = _uniq(
        (valuesToPreserve ?? []).concat(updatedEnabledFeatures)
      );
    }
    _set(draft.spec, 'enabled_features', updatedEnabledFeatures);
  });

  return updatedInstallation;
};
