import { Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1FindingCategory, V1GroupResponse } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableColumnDef,
  FindingLevelChip,
  NumberDisplay,
  TruncatedTextDisplay,
  UIFindingUtils,
} from '@endorlabs/ui-common';

import {
  FindingRiskMatrixReachability,
  FindingRiskMatrixSeverity,
  FindingRiskMatrixTableRow,
} from './types';
import {
  buildFindingRiskMatrixTableRows,
  getFilteredFindingGroups,
} from './utils';

const FindingRiskMatrixCell = ({
  severity,
  value,
  reachability,
}: {
  severity: FindingRiskMatrixSeverity;
  value: number;
  reachability?: FindingRiskMatrixReachability | 'total';
}) => {
  const theme = useTheme();

  const color =
    severity === 'total'
      ? theme.palette.text.primary
      : UIFindingUtils.getSeverityColor(severity, theme);

  return (
    <Typography
      component="span"
      sx={() => ({ color })}
      textAlign={reachability === 'total' ? 'end' : 'center'}
      variant="body1"
      fontWeight={600}
    >
      <NumberDisplay value={value} />
    </Typography>
  );
};

const getFindingRiskMatrixTableColumnDefs = (
  columnWidth?: number
): DataTableColumnDef<FindingRiskMatrixTableRow>[] => {
  const FindingRiskMatrixTableColumnDefs: DataTableColumnDef<FindingRiskMatrixTableRow>[] =
    [
      {
        accessorKey: 'severity',
        header: '',
        cell: (t) =>
          t.getValue() === 'total' ? (
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
              variant="body2"
              fontWeight={500}
            >
              <FM defaultMessage="Total" />
            </Typography>
          ) : (
            <FindingLevelChip size="xsmall" level={t.getValue()} />
          ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
      {
        accessorKey: 'reachable',
        header: (x) => {
          return (
            <TruncatedTextDisplay
              value="Reachable"
              start={11}
              end={11}
              width="100%"
              display="block"
              justifyContent="center"
              variant="body2"
              fontWeight={500}
            />
          );
        },
        cell: (t) => (
          <FindingRiskMatrixCell
            severity={t.row.getValue('severity')}
            value={t.getValue()}
          />
        ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
      {
        accessorKey: 'potential',
        header: () => (
          <TruncatedTextDisplay
            value="Potentially Reachable"
            width="100%"
            display="block"
            justifyContent="center"
            variant="body2"
            fontWeight={500}
          />
        ),
        cell: (t) => (
          <FindingRiskMatrixCell
            severity={t.row.getValue('severity')}
            value={t.getValue()}
          />
        ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
      {
        accessorKey: 'unreachable',
        header: () => (
          <TruncatedTextDisplay
            value="Unreachable"
            width="100%"
            display="block"
            justifyContent="center"
            variant="body2"
            fontWeight={500}
          />
        ),
        cell: (t) => (
          <FindingRiskMatrixCell
            severity={t.row.getValue('severity')}
            value={t.getValue()}
          />
        ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
      {
        accessorKey: 'total',
        header: (x) => {
          return (
            <TruncatedTextDisplay
              value="Total"
              width="100%"
              display="block"
              justifyContent="end"
              variant="body2"
              fontWeight={500}
            />
          );
        },
        cell: (t) => (
          <FindingRiskMatrixCell
            severity={t.row.getValue('severity')}
            value={t.getValue()}
            reachability="total"
          />
        ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
    ];
  return FindingRiskMatrixTableColumnDefs;
};

interface FindingRiskMatrixTableProps {
  activeFindingCategory?: V1FindingCategory;
  findingGroups?: V1GroupResponse['groups'];
  columnWidth?: number;
  isLoading?: boolean;
}

/**
 * Displays a table-like structure with Finding counts organized by severity & important attributes
 */
export const FindingRiskMatrixTableV2 = ({
  activeFindingCategory,
  findingGroups,
  columnWidth,
  isLoading,
}: FindingRiskMatrixTableProps) => {
  const sx = styles();

  const filteredFindingGroups = useMemo(
    () => getFilteredFindingGroups(findingGroups, activeFindingCategory),
    [activeFindingCategory, findingGroups]
  );

  const rows = useMemo(
    () => buildFindingRiskMatrixTableRows(filteredFindingGroups),
    [filteredFindingGroups]
  );

  return (
    <Box sx={sx.root} textAlign="right">
      <DataTable
        columns={getFindingRiskMatrixTableColumnDefs(columnWidth)}
        data={rows}
        isLoading={isLoading}
      />
    </Box>
  );
};

const styles = () => ({
  root: {
    ' .MuiTableContainer-root': {
      borderBottom: 0,
    },

    ' .MuiTableBody-root .MuiTableRow-root:last-of-type': {
      '.MuiTableCell-root': {
        borderBottom: 0,
      },
    },

    ' .MuiTableCell-root': {
      padding: '8px',
    },
  },
});
