import { omit as _omit } from 'lodash-es';
import create from 'zustand';
import { persist } from 'zustand/middleware';

// Definitions for current feature flags
export const FEATURE_FLAGS = [
  {
    id: 'ENABLE_CI_CD_TOOLS',
    name: 'Enable CI/CD Tools',
    description:
      'Enables components and functionality related to CI/CD Tools (UI-438)',
  },
  {
    id: 'ENABLE_VANTA',
    name: 'Enable Vanta Integration',
    description:
      'Enables components and functionality related to Vanta (PC-143)',
  },
  {
    id: 'ENABLE_GITHUB_ACTIONS',
    name: 'Enable Github Actions Data',
    description:
      'Enables components and functionality related to Github Actions (UI-501)',
  },
  {
    id: 'ENABLE_SLACK_INTEGRATION',
    name: 'Enable Slack Integration',
    description:
      'Enables components and functionality related to the Slack Integration (ASE-135)',
  },
  {
    id: 'ENABLE_SERVICE_REQUEST',
    name: 'Enable Service Request',
    description:
      'Enables components and functionality related to Service Request (UI-887)',
  },
  {
    id: 'ENABLE_DASHBOARD_FEED',
    name: 'Enable Dashboard Feed',
    description:
      'Enables components and functionality related to Dashboard Feed (UI-914)',
  },
  {
    id: 'ENABLE_CUSTOM_NOTIFICATION_TEMPLATES',
    name: 'Allow use of custom notification templates',
    description:
      'Enables components and functionality related to custom Webhook templates, etc',
  },
  {
    id: 'ENABLE_CUSTOM_PR_COMMENT_TEMPLATES',
    name: 'Allow use of custom PR comment templates',
    description:
      'Enables components and functionality related to custom PR comment templates',
  },
  {
    id: 'ENABLE_LICENSE_AND_BUNDLING',
    name: 'Allows licensing and bundling of features',
    description:
      'Enables licensing and bundling of features for premium tenants',
  },
  {
    id: 'ENABLE_IA_1_PAGE_IMPROVEMENTS',
    name: 'Enable IA page improvements',
    description: 'Enables new Findings page',
  },
  {
    id: 'ENABLE_REMEDIATIONS',
    name: 'Enable Remediations',
    description:
      'Enables components and functionality related to Remediations(CIA) (UI-772)',
  },
  {
    id: 'ENABLE_SAST_FEATURE',
    name: 'Enable SAST features',
    description:
      'Enables SAST feature in projects, findings, policy and other pages',
  },
  {
    id: 'ENABLE_SAST_PHASE_2_FEATURE',
    name: 'Enable SAST features phase 2',
    description:
      'Enables SAST feature in policy and rules enhancements (UI-1753)',
  },
  {
    id: 'ENABLE_CONSOLIDATED_SBOM',
    name: 'Enable Consolidated SBOM',
    description: 'Enables functionality related to Consolidated SBOM (UI-1454)',
  },
  {
    id: 'ENABLE_DASHBOARD_ANALYTICS_VIEW',
    name: 'Enable Dashboard Analytics View',
    description:
      'Enables components and functionality related to the Dashboard Analytics view (UI-555)',
  },
  {
    id: 'ENABLE_AZURE_INTEGRATION',
    name: 'Enable Azure Devops integration',
    description:
      'Enables components and functionality related to Azure Devops Integration (UI-1453) ',
  },
  {
    id: 'ENABLE_LLM',
    name: 'Enable LLM Discovery',
    description:
      'Enables the new OSS Explorer page with Hugging Face Models (UI-1201) ',
  },
  {
    id: 'ENABLE_LLM_DISCOVERY',
    name: 'Enable LLM Discovery Phase 2',
    description:
      'Enables components and functionality related to AI/LLM Discovery in customer’s repository (UI-1201)',
  },
  {
    id: 'ENABLE_PR_SCAN_OPTIONS',
    name: 'Enable PR Scan Options',
    description: 'Allows PR scans and comments for GitHub installations',
  },
  {
    id: 'ENABLE_AUTOMATED_GITHUB_PR',
    name: `Enable Automated GitHub PR`,
    description: `Allows Enable Automated GitHub PR's for Remediations`,
  },
] as const;

type FeatureFlagKeys = (typeof FEATURE_FLAGS)[number]['id'];

export type FeatureFlagsStore = Record<FeatureFlagKeys, boolean>;

type MigrationFn = (
  persistedState: FeatureFlagsStore,
  version: number
) =>
  | Partial<FeatureFlagsStore>
  | Promise<Partial<FeatureFlagsStore>>
  | undefined;

/**
 * !!! REMINDER !!!
 * Feature flag changes will not automatically be picked up
 * unless the store version is updated.
 */

// Update on breaking changes.
const VERSION = 19;

const DEFAULT_FLAG_VALUES: FeatureFlagsStore = {
  ENABLE_CI_CD_TOOLS: true,
  ENABLE_CONSOLIDATED_SBOM: false,
  ENABLE_CUSTOM_NOTIFICATION_TEMPLATES: true,
  ENABLE_CUSTOM_PR_COMMENT_TEMPLATES: true,
  ENABLE_DASHBOARD_FEED: false,
  ENABLE_DASHBOARD_ANALYTICS_VIEW: false,
  ENABLE_GITHUB_ACTIONS: true,
  ENABLE_IA_1_PAGE_IMPROVEMENTS: true,
  ENABLE_LICENSE_AND_BUNDLING: true,
  ENABLE_LLM: true,
  ENABLE_LLM_DISCOVERY: false,
  ENABLE_REMEDIATIONS: true,
  ENABLE_SAST_PHASE_2_FEATURE: false,
  ENABLE_SAST_FEATURE: true,
  ENABLE_SERVICE_REQUEST: false,
  ENABLE_SLACK_INTEGRATION: true,
  ENABLE_VANTA: true,
  ENABLE_AZURE_INTEGRATION: false,
  ENABLE_PR_SCAN_OPTIONS: false,
  ENABLE_AUTOMATED_GITHUB_PR: false,
};

/**
 * Wipe existing state back to defaults
 */
const resetToDefaults = (stateObj: FeatureFlagsStore): FeatureFlagsStore => ({
  ...stateObj,
  ...DEFAULT_FLAG_VALUES,
});

const getStateWithoutFlag = (
  stateObj: FeatureFlagsStore,
  flag: string
): Partial<FeatureFlagsStore> => _omit({ ...stateObj }, flag);

/**
 * To remove a feature flag entirely:
 *  - Remove the feature definition from FEATURE_FLAGS
 *  - Remove the feature id from DEFAULT_FLAG_VALUES
 *  - Increment the VERSION number
 *  - Add a VERSION function below that returns store without feature definition
 */
const migrations: Record<number, MigrationFn> = {
  [11]: (persistedState) =>
    getStateWithoutFlag(persistedState, 'ENABLE_EXCEPTION_POLICIES'),
};

export const useFeatureFlags = create(
  persist<FeatureFlagsStore>(
    (setState, getState) => {
      return { ...DEFAULT_FLAG_VALUES };
    },
    {
      // @ts-expect-error - Issues narrowing unknown to FeatureFlagsStore
      migrate: migrations[VERSION] ?? resetToDefaults,

      name: 'feature-flags-store',
      version: VERSION,
    }
  )
);
