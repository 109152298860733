import { noop as _noop } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { IconEdit, IconLink, IconSidebarRight, IconTrash } from '../../themes';
import { DataTableRowActionTypeKeys } from './types';

export const PAGE_SIZES = [25, 50, 100];

export const DEFAULT_PAGE_SIZE = PAGE_SIZES[1];

/** Number of pixels "leftover" after calculating table size.
 * Redistribute column widths if above this threshold.
 */
export const COLUMN_WIDTH_REDISTRIBUTION_THRESHOLD = 120;

/** Percentage of "leftover" space to distribute to the last column. */
export const LAST_COLUMN_REDISTRIBUTION_RATIO = 0.5;

/**
 * Consistent definitions for frequently used row actions
 * like Open Drawer, Edit, Delete, etc
 */
export const CommonDataTableRowActions = {
  [DataTableRowActionTypeKeys.ACTIVATE_DRAWER]: {
    Icon: IconSidebarRight,
    label: <FM defaultMessage="View Details" />,
    onClick: _noop,
    type: DataTableRowActionTypeKeys.ACTIVATE_DRAWER,
  },

  [DataTableRowActionTypeKeys.DELETE_RESOURCE]: {
    Icon: IconTrash,
    isDestructive: true,
    label: <FM defaultMessage="Delete" />,
    onClick: _noop,
    type: DataTableRowActionTypeKeys.DELETE_RESOURCE,
  },

  [DataTableRowActionTypeKeys.EDIT_RESOURCE]: {
    Icon: IconEdit,
    label: <FM defaultMessage="Edit" />,
    onClick: _noop,
    type: DataTableRowActionTypeKeys.EDIT_RESOURCE,
  },

  [DataTableRowActionTypeKeys.LINK]: {
    Icon: IconLink,
    label: <FM defaultMessage="Link" />,
    onClick: _noop,
    type: DataTableRowActionTypeKeys.LINK,
  },
};
