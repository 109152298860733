import { Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';

import {
  GITHUB_ACTION_PACKAGE_SUFFIX,
  UIPackageVersionUtils,
} from '../../domains/PackageVersion';
import { PackageIconDisplay } from '../PackageIconDisplay';

interface CiWorkflowNameDisplayProps {
  name: string;
  link?: string;
  relativePath?: string;
  size?: 'inherit' | 'small' | 'medium' | 'large';
  showIcon?: boolean;
  suffix?: string;
}

/**
 * Display for a CI workflow name.
 */
export const CiWorkflowNameDisplay = ({
  name: packageName,
  relativePath = '',
  size = 'small',
  showIcon = true,
  suffix = GITHUB_ACTION_PACKAGE_SUFFIX,
}: CiWorkflowNameDisplayProps) => {
  const { spacing } = useTheme();
  const { ecosystem, label } = useMemo(
    () => UIPackageVersionUtils.parsePackageName(packageName),
    [packageName]
  );

  // Add the relative path and suffix to the label
  const name = useMemo(() => {
    let packageName = label;
    if (relativePath) {
      // handle windows paths, replace all '\' with '/'
      const formattedPath = relativePath.replace(/\\/g, '/');

      // remove the leading '/' if present
      const prefixPath = formattedPath.replace(/^\//, '');

      packageName = `${prefixPath}/${packageName}`;
    }
    if (suffix) {
      packageName = `${packageName}${suffix}`;
    }
    return packageName;
  }, [label, relativePath, suffix]);

  return (
    <Stack direction="row" spacing={spacing(2)} alignItems="center">
      {showIcon && (
        <PackageIconDisplay
          ecosystem={ecosystem}
          displayDefault="package"
          size={size}
        />
      )}
      <Typography component="span" fontSize={size} variant="inherit">
        {name}
      </Typography>
    </Stack>
  );
};
