import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { RowSelectionState, Table } from '@tanstack/react-table';
import { without as _without } from 'lodash-es';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  ContextContextType,
  V1Ecosystem,
  V1ScanState,
} from '@endorlabs/api_client';
import { DEFAULT_ECOSYSTEMS } from '@endorlabs/endor-core/Ecosystem';
import {
  FILTER_COMPARATORS,
  filterExpressionBuilders,
} from '@endorlabs/filters';
import {
  PackageContexture,
  PackageSource,
  ProjectResource,
  RepoVersionResource,
  useCountPackageVersions,
  useFeatureFlags,
  useQueryPackageVersions,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  EmptyState,
  MultiSelectInput,
  UIProjectUtils,
  useDataTablePaginator,
  useTanstackTableRef,
} from '@endorlabs/ui-common';
import { EcosystemLabel } from '@endorlabs/ui-common/domains/Package';

import {
  mapToPackageVersionTableRows,
  PackageVersionsTable,
  PackageVersionsTableRow,
  useScanIssuesDetailDrawer,
} from '../../components';
import {
  FIVE_MINUTES_IN_MILLISECONDS,
  TEN_SECONDS_IN_MILLISECONDS,
} from '../../constants';
import {
  FilterBar,
  FilterFieldConfig,
  filterFieldTransformBuilders,
  useFilterContext,
  withFilterProvider,
} from '../../domains/filters';
import {
  buildPackageSourceToggleOptions,
  PackageSourceToggles,
  usePackageVersionDetailDrawer,
} from '../../domains/PackageVersion';

export interface ProjectVersionPackagesProps {
  isLoading?: boolean;
  namespace: string;
  project?: ProjectResource;
  repositoryVersion?: RepoVersionResource;
}

const PACKAGES_FILTER = `spec.ecosystem!=${V1Ecosystem.GithubAction}`;
const WORKFLOWS_FILTER = `spec.ecosystem==${V1Ecosystem.GithubAction}`;

const FILTER_FIELDS: FilterFieldConfig<any>[] = [
  {
    id: 'PackageVersion:spec.ecosystem',
    ...filterFieldTransformBuilders.fromFilter({
      key: 'spec.ecosystem',
      comparator: FILTER_COMPARATORS.IN,
    }),
    renderInput: ({ onChange, value }) => (
      <MultiSelectInput
        label={<FM defaultMessage="Ecosystem" />}
        onChange={onChange}
        value={value}
        // Excluding GitHub action from ecosystem dropdown due to the exsting
        // tabs for Packages/CI Workflows
        options={_without(DEFAULT_ECOSYSTEMS, V1Ecosystem.GithubAction).map(
          (value) => ({
            value,
            label: <EcosystemLabel value={value} />,
          })
        )}
      />
    ),
  } satisfies FilterFieldConfig<V1Ecosystem[]>,
];

const ProjectVersionPackagesBase = ({
  isLoading: isProjectLoading,
  namespace,
  project,
  repositoryVersion,
}: ProjectVersionPackagesProps) => {
  const isGithubActionsEnabled = useFeatureFlags(
    (s) => s.ENABLE_GITHUB_ACTIONS
  );

  const { formatMessage: fm } = useIntl();
  const [packageContexture, setPackageContexture] = useState<
    PackageContexture.Packages | PackageContexture.GithubActions
  >(PackageContexture.Packages);

  const [packageSource, setPackageSource] = useState(PackageSource.Packages);

  const isScanning =
    repositoryVersion?.context.type !== ContextContextType.CiRun &&
    project?.processing_status?.scan_state === V1ScanState.Ingesting;

  const [selectedRows, setSelectedRows] = useState({});
  const tableRef = useTanstackTableRef<PackageVersionsTableRow>();

  const { DetailDrawer, permalinkEffect } = usePackageVersionDetailDrawer();
  const { DetailDrawer: ScanIssuesDetailDrawer } = useScanIssuesDetailDrawer();

  const {
    filter: filterExpression,
    clearFilter,
    _state: filterState,
  } = useFilterContext();

  // shared filter for packages count and list
  const relatedFilterExpression =
    UIProjectUtils.getProjectRelatedFilterExpressions(
      project,
      repositoryVersion
    );

  const qCountProjectPackages = useCountPackageVersions(
    namespace,
    {
      enabled: !!relatedFilterExpression,
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
      refetchInterval: isScanning ? TEN_SECONDS_IN_MILLISECONDS : undefined,
    },
    {
      filter:
        relatedFilterExpression &&
        filterExpressionBuilders.and([
          relatedFilterExpression,
          PACKAGES_FILTER,
        ]),
    }
  );

  const qCountProjectWorkflows = useCountPackageVersions(
    namespace,
    {
      enabled: !!relatedFilterExpression,
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
      refetchInterval: isScanning ? TEN_SECONDS_IN_MILLISECONDS : undefined,
    },
    {
      filter:
        relatedFilterExpression &&
        filterExpressionBuilders.and([
          relatedFilterExpression,
          WORKFLOWS_FILTER,
        ]),
    }
  );

  const projectPackagesCount = useMemo(() => {
    return packageSource === PackageSource.GithubActions
      ? qCountProjectWorkflows.data?.count
      : qCountProjectPackages.data?.count;
  }, [
    packageSource,
    qCountProjectPackages.data?.count,
    qCountProjectWorkflows.data?.count,
  ]);

  const paginator = useDataTablePaginator({
    totalCount: projectPackagesCount,
  });

  const isPackagesEmpty =
    !qCountProjectPackages.isLoading && !qCountProjectPackages.data?.count;
  const isWorkflowEmpty =
    !qCountProjectWorkflows.isLoading && !qCountProjectWorkflows.data?.count;

  const [packageSourceValue, setPackageSourceValue] = useState<PackageSource>(
    PackageSource.Packages
  );

  const packageSourceToggleOptions = useMemo(() => {
    return buildPackageSourceToggleOptions({
      packages: qCountProjectPackages.data?.count,
      ciWorkflows: qCountProjectWorkflows.data?.count,
    });
  }, [qCountProjectPackages.data?.count, qCountProjectWorkflows.data?.count]);

  useEffect(() => {
    if (packageSourceValue) {
      setPackageSource(packageSourceValue);
      const contexture =
        packageSourceValue === PackageSource.GithubActions
          ? PackageContexture.GithubActions
          : PackageContexture.Packages;
      setPackageContexture(contexture);
    }
  }, [packageSourceValue]);

  const packageVersionSourceFilter = useMemo(() => {
    return packageSource === PackageSource.GithubActions
      ? WORKFLOWS_FILTER
      : PACKAGES_FILTER;
  }, [packageSource]);

  const packageVersionsFilter = useMemo(() => {
    if (relatedFilterExpression && filterExpression) {
      if (packageSource === PackageSource.Packages) {
        return filterExpressionBuilders.and([
          relatedFilterExpression,
          packageVersionSourceFilter,
          filterExpression,
        ]);
      }
      const searchValue = filterState.search;
      if (packageSource === PackageSource.GithubActions && searchValue) {
        return filterExpressionBuilders.and([
          relatedFilterExpression,
          packageVersionSourceFilter,
          `meta.name matches "${searchValue}" or meta.tags matches "${searchValue}"`,
        ]);
      }
      return filterExpressionBuilders.and([
        relatedFilterExpression,
        packageVersionSourceFilter,
      ]);
    } else if (relatedFilterExpression) {
      return filterExpressionBuilders.and([
        relatedFilterExpression,
        packageVersionSourceFilter,
      ]);
    }
    return packageVersionSourceFilter;
  }, [
    filterExpression,
    filterState.search,
    packageSource,
    packageVersionSourceFilter,
    relatedFilterExpression,
  ]);

  // use query for package versions, including metrics
  const qQueryPackageVersions = useQueryPackageVersions(
    namespace,
    {
      enabled: !!relatedFilterExpression,
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
      refetchInterval: isScanning ? TEN_SECONDS_IN_MILLISECONDS : undefined,
    },
    {
      ...paginator.getListParameters(),
      filter: packageVersionsFilter,
    }
  );

  const projectPackages = useMemo(
    () =>
      mapToPackageVersionTableRows(
        qQueryPackageVersions.data,
        packageContexture
      ),
    [qQueryPackageVersions.data, packageContexture]
  );

  const handleClickDetail = useCallback(
    (row?: PackageVersionsTableRow) => {
      // TODO: handle action for fallback row
      if (!row?.uuid || !row?.namespace) return;

      DetailDrawer.activate(
        {
          packageNamespace: row.namespace,
          packageUuid: row.uuid,
        },
        {
          namespace: namespace,
          uuid: row.uuid,
        }
      );
    },
    [DetailDrawer, namespace]
  );

  useEffect(() => {
    if (!DetailDrawer.isOpen && Object.keys(selectedRows).length > 0) {
      if (tableRef.current) {
        const currentTable = tableRef.current as Table<PackageVersionsTableRow>;
        currentTable.resetRowSelection();
        setSelectedRows({});
      }
    }
  }, [DetailDrawer, selectedRows, tableRef]);

  const handleClickResolutionErrors = useCallback(
    (row?: PackageVersionsTableRow) => {
      // TODO: handle action for fallback row
      if (!row?.uuid || !row?.namespace) return;

      const packageVersion = qQueryPackageVersions.data?.list?.objects?.find(
        (pv) => pv.uuid === row.uuid
      );

      if (project && packageVersion) {
        ScanIssuesDetailDrawer.activate(
          {
            focusResourceUuid: packageVersion.uuid,
            namespace: row.namespace,
            projectUuid: project.uuid,
            contextId: packageVersion.context.id,
            contextType: packageVersion.context.type,
          },
          {
            namespace: row.namespace,
            projectUuid: project.uuid,
            scanContext: packageVersion.context,
            focusResource: packageVersion,
          }
        );
      }
    },
    [ScanIssuesDetailDrawer, project, qQueryPackageVersions.data]
  );

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  useLayoutEffect(
    () =>
      permalinkEffect({
        packages: projectPackages,
      }),
    [permalinkEffect, projectPackages, packageContexture]
  );

  const packageSourceSingular =
    packageSource === PackageSource.GithubActions
      ? fm({ defaultMessage: 'CI Workflow' })
      : fm({ defaultMessage: 'Package' });

  const packageSourcePlural =
    packageSource === PackageSource.GithubActions
      ? fm({ defaultMessage: 'CI Workflows' })
      : fm({ defaultMessage: 'Packages' });

  const isLoadingCounts =
    qCountProjectPackages.isLoading || qCountProjectWorkflows.isLoading;

  const isLoading =
    isProjectLoading || isLoadingCounts || qQueryPackageVersions.isLoading;

  const isEmptyState = !isLoading && isPackagesEmpty && isWorkflowEmpty;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM
                defaultMessage="There are no {packageSourcePlural} in this project version"
                values={{ packageSourcePlural }}
              />
            }
            description={
              <FM
                defaultMessage="As {packageSourcePlural} are added to this project, they will appear here."
                values={{ packageSourcePlural }}
              />
            }
          ></EmptyState>
        </Grid>
      )}
      {!isEmptyState && (
        <>
          <Grid item>
            {isGithubActionsEnabled && (
              <PackageSourceToggles
                hideCounts={isLoadingCounts}
                options={packageSourceToggleOptions}
                onChange={(_, value) => value && setPackageSourceValue(value)}
                value={packageSource}
              />
            )}
            <FilterBar
              fields={
                packageSource === PackageSource.Packages ? FILTER_FIELDS : []
              }
            />
          </Grid>
          <Grid item>
            <Card>
              <CardHeader
                title={
                  isLoading ? (
                    <Skeleton width="60%" />
                  ) : (
                    <Typography>
                      {isScanning ? (
                        <FM
                          defaultMessage="<bold>{totalCount, number}</bold> {totalCount, plural, one { {packageSourceSingular}} other {{packageSourcePlural}}} scanned"
                          values={{
                            totalCount: projectPackagesCount,
                            bold: (v) => <strong>{v}</strong>,
                            packageSourceSingular,
                            packageSourcePlural,
                          }}
                        />
                      ) : (
                        <FM
                          defaultMessage="Showing <bold>{filteredCount, number}</bold> of {totalCount, plural,
                        one {<bold>#</bold> {packageSourceSingular}} other {<bold>#</bold> {packageSourcePlural}}}"
                          values={{
                            filteredCount: projectPackages.length,
                            totalCount: projectPackagesCount,
                            bold: (v) => <strong>{v}</strong>,
                            packageSourceSingular,
                            packageSourcePlural,
                          }}
                        />
                      )}
                    </Typography>
                  )
                }
              />
              <CardContent>
                <PackageVersionsTable
                  ref={tableRef}
                  packageContexture={packageContexture}
                  isLoading={qQueryPackageVersions.isLoading}
                  data={projectPackages}
                  enablePagination
                  enableRowSelection
                  onClickResolutionErrors={handleClickResolutionErrors}
                  paginator={paginator}
                  showVersion
                  onClickDetail={
                    packageSource === PackageSource.Packages
                      ? handleClickDetail
                      : undefined
                  }
                  emptyStateProps={{
                    title: (
                      <FM
                        defaultMessage="No {packageSourcePlural} match the filter criteria"
                        values={{ packageSourcePlural }}
                      />
                    ),
                    children: (
                      <ButtonCancel onClick={clearFilter}>
                        <FM defaultMessage="Clear Filters" />
                      </ButtonCancel>
                    ),
                  }}
                  onRowSelectionChange={handleRowSelection}
                />
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
};

/**
 * Wire Filter Context to page
 */
export const ProjectVersionPackages = withFilterProvider(
  ProjectVersionPackagesBase,
  {
    displayName: 'ProjectVersionPackages',
    searchKeys: ['meta.name', 'meta.tags'],
  }
);
