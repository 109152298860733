import {
  Box,
  Chip,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem, V1FindingTags } from '@endorlabs/api_client';
import {
  getFindingAttributeValues,
  getFindingTitle,
} from '@endorlabs/endor-core/Finding';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { QueryFindingsResponseObject } from '@endorlabs/queries';
import {
  AccordionCard,
  CiWorkflowNameDisplay,
  DescriptionTooltip,
  FindingAttributesArrayDisplay,
  FindingLevelChip,
  GITHUB_ACTION_PACKAGE_SUFFIX,
  IconClock,
  IconTool,
  Link,
  MetadataList,
  MetadataMetric,
  NilDisplay,
  PackageNameDisplay,
  ProjectNameDisplay,
  RelativeTimeDisplay,
  Status,
  StatusIndicator,
} from '@endorlabs/ui-common';
import {
  ProjectInfoCacheEntry,
  useProjectInfoCache,
} from '@endorlabs/ui-common/domains/Projects';

import {
  getDependencyPath,
  getPackageVersionPath,
  getPackageVersionRedirectPath,
  getProjectPath,
} from '../../../routes';
import { MAX_FINDING_TITLE_LENGTH } from '../constants';
import { FindingSummaryAccordionContent } from './FindingSummaryAccordionContentV2';
import { FindingSummaryAccordionSkeletonV2 } from './FindingSummaryAccordionSkeletonV2';

const getFindingSummaryAccordionMetadata = (
  finding: QueryFindingsResponseObject,
  projectInfo?: ProjectInfoCacheEntry,
  hideProjectMetadata = false
): MetadataMetric[] => {
  const findingAttributes = getFindingAttributeValues(finding);
  const findingNamespace = finding.tenant_meta.namespace;

  const isSelfFinding = finding.spec.finding_tags.includes(V1FindingTags.Self);
  const isOssFinding = finding.tenant_meta.namespace === NAMESPACES.OSS;

  // For Findings from OSS, use the package version redirect to the target
  // package under the oss namespace (oss explorer).
  // For tenant Findings, use the link to the dependency under the active
  // namespace.
  const dependencyPath = isOssFinding
    ? getPackageVersionRedirectPath({
        tenantName: NAMESPACES.OSS,
        packageVersionName: finding.spec.target_dependency_package_name,
      })
    : getDependencyPath({
        tenantName: findingNamespace,
        uuid: finding.spec.target_uuid,
      });

  // for dependency findings, show the affected entity
  const packageVersionResource =
    finding.meta.references.PackageVersion?.list?.objects[0];

  const metadata: MetadataMetric[] = [];

  // Show the Dependency, if target_uuid is set, and the finding is not for a Package itself
  if (finding.spec.target_uuid && !isSelfFinding) {
    metadata.push({
      label: <FM defaultMessage="Dependency" />,
      value: finding.spec.target_dependency_package_name ? (
        <Link to={dependencyPath}>
          <PackageNameDisplay
            name={finding.spec.target_dependency_package_name}
          />
        </Link>
      ) : (
        <NilDisplay variant="text" />
      ),
    });
  }

  // Show the package, unless this finding is for a Package itself
  if (finding.meta.parent_kind === 'PackageVersion' && !isSelfFinding) {
    if (packageVersionResource?.spec.ecosystem === V1Ecosystem.GithubAction) {
      metadata.push({
        label: <FM defaultMessage="CI Workflow" />,
        value: packageVersionResource ? (
          <Link
            to={getPackageVersionPath({
              tenantName: findingNamespace,
              uuid: packageVersionResource.uuid,
            })}
          >
            <CiWorkflowNameDisplay
              name={packageVersionResource.meta.name}
              relativePath={packageVersionResource.spec.relative_path}
              size="inherit"
              showIcon
              suffix={GITHUB_ACTION_PACKAGE_SUFFIX}
            />
          </Link>
        ) : (
          <NilDisplay variant="text" />
        ),
      });
    } else {
      metadata.push({
        label: <FM defaultMessage="Package" />,
        value: packageVersionResource ? (
          <Link
            to={getPackageVersionPath({
              tenantName: findingNamespace,
              uuid: packageVersionResource.uuid,
            })}
          >
            <PackageNameDisplay name={packageVersionResource.meta.name} />
          </Link>
        ) : (
          <NilDisplay variant="text" />
        ),
      });
    }
  }

  // Show the Project, unless disabled
  if (!hideProjectMetadata) {
    metadata.push({
      label: <FM defaultMessage="Project" />,
      value: projectInfo ? (
        <Link
          to={getProjectPath({
            tenantName: findingNamespace,
            uuid: projectInfo.uuid,
          })}
        >
          <ProjectNameDisplay {...projectInfo} />
        </Link>
      ) : (
        <NilDisplay variant="text" />
      ),
    });
  }

  // Always show finding attributes
  metadata.push({
    label: <FM defaultMessage="Attributes" />,
    value: <FindingAttributesArrayDisplay value={findingAttributes} />,
  });

  return metadata;
};

const getFindingSummaryAccordionTitle = (
  finding: QueryFindingsResponseObject,
  forceFindingDescriptionTitle?: boolean
) => {
  const isSelfFinding = finding.spec.finding_tags.includes(V1FindingTags.Self);

  // for dependency findings, show the affected entity
  const packageResource =
    finding.meta.references.PackageVersion?.list?.objects[0];
  if (!forceFindingDescriptionTitle && !isSelfFinding && packageResource) {
    if (packageResource.spec.ecosystem === V1Ecosystem.GithubAction) {
      return (
        <CiWorkflowNameDisplay
          name={packageResource.meta.name}
          relativePath={packageResource.spec.relative_path}
          size="inherit"
          showIcon
          suffix={GITHUB_ACTION_PACKAGE_SUFFIX}
        />
      );
    }
    return <PackageNameDisplay name={packageResource.meta.name} showVersion />;
  }

  const title = forceFindingDescriptionTitle
    ? finding.meta.description
    : getFindingTitle(finding) ?? '';

  if (title.length < MAX_FINDING_TITLE_LENGTH) return title;
  return (
    <DescriptionTooltip title={title}>
      <span>{title.slice(0, MAX_FINDING_TITLE_LENGTH)}&hellip;</span>
    </DescriptionTooltip>
  );
};

export type FindingSummaryAccordionV2Props = {
  finding: QueryFindingsResponseObject;
  hideProjectMetadata?: boolean;
  isLoading?: boolean;
  namespace: string;
  forceFindingDescriptionTitle?: boolean;
};

/**
 * Replaces {@see FindingSummaryAccordion}
 */
export const FindingSummaryAccordionV2 = ({
  finding,
  hideProjectMetadata,
  isLoading,
  namespace,
  forceFindingDescriptionTitle,
}: FindingSummaryAccordionV2Props) => {
  const theme = useTheme();

  const projectUuid = finding?.spec?.project_uuid;

  const qProjectInfoCache = useProjectInfoCache(namespace, {
    enabled: projectUuid && !hideProjectMetadata,
  });
  const projectInfo = projectUuid
    ? qProjectInfoCache.cache.get(projectUuid)
    : undefined;

  if (isLoading) return <FindingSummaryAccordionSkeletonV2 />;

  const hasFixAvailable = finding.spec.finding_tags?.includes(
    V1FindingTags.FixAvailable
  );
  const isApproximation = finding.spec.approximation;

  const findingsTitle = getFindingSummaryAccordionTitle(
    finding,
    forceFindingDescriptionTitle
  );

  const sx = styles(theme);

  return (
    <AccordionCard
      elevation={0}
      id={`FindingSummaryAccordion-${finding.uuid}`}
      title={
        <Stack
          alignItems="flex-start"
          direction="row"
          flexWrap="nowrap"
          spacing={2}
        >
          <FindingLevelChip level={finding.spec.level} />

          <Tooltip
            title={
              hasFixAvailable ? (
                <FM defaultMessage="A patch is available for this finding" />
              ) : (
                <FM defaultMessage="No patch is available for this finding" />
              )
            }
          >
            <Box sx={sx.summaryItemWrapper}>
              <IconTool
                fontSize="small"
                htmlColor={
                  hasFixAvailable
                    ? theme.palette.data.yellow
                    : theme.palette.grey[200]
                }
              />
            </Box>
          </Tooltip>

          <Typography variant="h3" sx={sx.summaryTitle}>
            {findingsTitle}
          </Typography>

          <Box sx={sx.summaryItemWrapper}>
            <Chip
              icon={<IconClock />}
              label={<RelativeTimeDisplay value={finding.meta.create_time} />}
              size="small"
              sx={sx.summaryChip}
            />
          </Box>

          {isApproximation && (
            <Box sx={sx.summaryItemWrapper}>
              <StatusIndicator
                status={Status.PartialSuccess}
                tooltipNode={
                  <FM defaultMessage="This finding is based on an approximate dependency. Package resolution may be missing or incomplete." />
                }
              />
            </Box>
          )}
        </Stack>
      }
      summaryContent={
        <Box
          sx={{
            '& .MuiChip-root': {
              backgroundColor: (t) => t.palette.grey[100],
            },
          }}
        >
          <MetadataList
            disableDivider
            metrics={getFindingSummaryAccordionMetadata(
              finding,
              projectInfo,
              hideProjectMetadata
            )}
          />
        </Box>
      }
    >
      <FindingSummaryAccordionContent finding={finding} />
    </AccordionCard>
  );
};

function styles({ palette, spacing }: Theme) {
  return {
    summaryChip: {
      backgroundColor: palette.grey[100],
    },
    summaryItemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: spacing(6),
    },
    summaryTitle: {
      lineHeight: spacing(6),
      wordBreak: 'break-all',
    },
  };
}
