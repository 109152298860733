import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeEvent, ReactNode, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

export type ControlledRadioProps = RadioProps & {
  helperText?: ReactNode;
  label?: ReactNode;
};

export type ControlledRadioGroupProps = RadioGroupProps & {
  htmlName?: string;
  name: string;
  // Label for the entire radio group
  label: ReactNode;
  radioRecords: ControlledRadioProps[];
};

/**
 * A composite component that reduces boilerplate for the common pattern of a RadioGroup controlled by react-hook-form.
 * Output radios will provide a series of possible values for a single named input.
 * Keep in mind that the DOM always casts radio values to a string.
 *
 * Combines component interfaces, with most props belonging to RadioGroup.
 * Handles render prop & common error state patterns.
 */
export const ControlledRadioGroup = ({
  htmlName,
  id,
  label,
  name,
  radioRecords,
  ...radioGroupProps
}: ControlledRadioGroupProps) => {
  const { space } = useTheme();

  const { clearErrors, control } = useFormContext();
  const { field, fieldState } = useController({
    control,
    name,
  });

  const handleChange = (evt: ChangeEvent) => {
    field.onChange(evt);
    field.onBlur();
  };

  useEffect(() => {
    clearErrors(name);
  }, [clearErrors, name]);

  const errorMessage = fieldState.error?.message;

  const labelId = `${id}-label`;

  const radioControls = radioRecords.map(
    ({ helperText, label, value, ...radioProps }) => {
      let output = (
        <FormControlLabel
          control={
            <Radio
              {...radioProps}
              checked={field.value === value}
              onChange={handleChange}
            />
          }
          key={value as string}
          label={label}
          value={value}
        />
      );

      if (helperText) {
        output = (
          <Stack>
            {output}
            <Typography marginLeft={space.md} variant="caption">
              {helperText}
            </Typography>
          </Stack>
        );
      }

      return output;
    }
  );

  return (
    <FormControl error={!!errorMessage}>
      <Stack rowGap={space.xs}>
        <FormLabel component="legend" id={labelId}>
          {label}
        </FormLabel>

        <RadioGroup
          {...radioGroupProps}
          aria-labelledby={labelId}
          name={htmlName}
          onChange={handleChange}
        >
          {radioControls}
        </RadioGroup>
      </Stack>
    </FormControl>
  );
};
