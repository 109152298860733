import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  FormLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import produce from 'immer';
import { get as _get, set as _set } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import {
  ControlledCheckboxV2,
  ControlledTextField,
  ControlledTextFieldArray,
  ControlledTextFieldSelect,
  RowStack,
} from '@endorlabs/ui-common';
import { defaultNilsDeep } from '@endorlabs/utils/object';

import { DEFAULT_SCAN_PROFILE_FIELD_VALUES } from '../../constants';
import { getLanguageOptions } from './utils';

export type FormUpsertScanProfileProps = {
  isLoading?: boolean;
  namespace: string;
  onSubmit: (data: ScanProfileResource) => void;
  scanProfile?: ScanProfileResource;
};

export const FormUpsertScanProfile = ({
  isLoading,
  onSubmit,
  scanProfile,
}: FormUpsertScanProfileProps) => {
  const { formatMessage: fm } = useIntl();
  const { palette, space } = useTheme();

  const formMethods = useForm<ScanProfileResource>({
    defaultValues: DEFAULT_SCAN_PROFILE_FIELD_VALUES,
  });
  const { control, handleSubmit: hookFormSubmit, reset } = formMethods;

  // reset the form with a resource, if provided
  useEffect(() => {
    if (scanProfile) {
      // Set default form values from the provided model
      reset(defaultNilsDeep(scanProfile, DEFAULT_SCAN_PROFILE_FIELD_VALUES));
    }
  }, [reset, scanProfile]);

  const onBeforeSubmit = (fieldValues: ScanProfileResource) => {
    // remove empy text field array values
    const fields = [
      'spec.automated_scan_parameters.additional_environment_variables',
      'spec.automated_scan_parameters.bazel_configuration.bazel_exclude_targets',
      'spec.automated_scan_parameters.bazel_configuration.bazel_include_targets',
      'spec.automated_scan_parameters.excluded_paths',
      'spec.automated_scan_parameters.included_paths',
    ];

    const clean = produce(fieldValues, (draft) => {
      for (const path of fields) {
        const values = _get(draft, path);
        if (Array.isArray(values) && values?.length) {
          _set(
            draft,
            path,
            values.filter((v) => !!v)
          );
        }
      }
    });

    onSubmit(clean);
  };

  const languageOptions = useMemo(
    () => getLanguageOptions(scanProfile?.spec.automated_scan_parameters),
    [scanProfile?.spec.automated_scan_parameters]
  );

  const isEdit = !!scanProfile;

  return (
    <FormProvider {...formMethods}>
      <form
        id="FormUpsertScanProfile"
        noValidate
        onSubmit={hookFormSubmit(onBeforeSubmit)}
      >
        <Stack direction="column" spacing={space.sm}>
          <RowStack justifyContent="flex-end">
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              {isEdit ? (
                <FM defaultMessage="Save Scan Profile" />
              ) : (
                <FM defaultMessage="Create Scan Profile" />
              )}
            </LoadingButton>
          </RowStack>

          <Card>
            <CardContent sx={{ borderBottom: `1px solid ${palette.divider}` }}>
              <Stack direction="column" spacing={space.md}>
                <Stack
                  direction="column"
                  spacing={space.sm}
                  component="fieldset"
                >
                  <FormLabel component="legend">
                    <Typography variant="h2" color="text.primary">
                      <FM defaultMessage="General" />
                    </Typography>
                  </FormLabel>

                  <Box width={300}>
                    <ControlledTextField
                      control={control}
                      defaultValue=""
                      label={<FM defaultMessage="Scan Profile Name" />}
                      name="meta.name"
                      placeholder={fm({
                        defaultMessage: 'Enter a name for your scan profile',
                      })}
                      required
                    />
                  </Box>
                </Stack>

                <Stack
                  direction="column"
                  spacing={space.sm}
                  component="fieldset"
                >
                  <FormLabel component="legend">
                    <Typography variant="h3" color="text.primary">
                      <FM defaultMessage="GitHub App Features" />
                    </Typography>
                  </FormLabel>

                  <Stack direction="column" spacing={space.xs}>
                    <ControlledCheckboxV2
                      label={
                        <FM defaultMessage="Enable Pull Request Comments" />
                      }
                      name="spec.automated_scan_parameters.enable_pr_comments"
                    />
                    <ControlledCheckboxV2
                      label={<FM defaultMessage="Enable Remediation Action" />}
                      name="spec.automated_scan_parameters.enable_remediation_action"
                    />
                    <ControlledCheckboxV2
                      label={<FM defaultMessage="Enable Automated PR Scans" />}
                      name="spec.automated_scan_parameters.enable_automated_pr_scans"
                    />
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>

            <CardContent sx={{ borderBottom: `1px solid ${palette.divider}` }}>
              <Stack direction="column" spacing={space.sm} component="fieldset">
                <FormLabel component="legend">
                  <Typography variant="h2" color="text.primary">
                    <FM defaultMessage="Languages" />
                  </Typography>
                </FormLabel>

                <RowStack gap={space.sm}>
                  <Box width={300}>
                    <ControlledTextFieldSelect
                      helperText={
                        <FM defaultMessage="Select which languages to scan" />
                      }
                      label={<FM defaultMessage="Languages" />}
                      name="spec.automated_scan_parameters.languages"
                      options={languageOptions.scanLanguages}
                      placeholder={fm({
                        defaultMessage: 'Use Defaults',
                      })}
                    />
                  </Box>

                  <Box width={300}>
                    <ControlledTextFieldSelect
                      helperText={
                        <FM defaultMessage="Generate call graphs for these languages" />
                      }
                      label={<FM defaultMessage="Call Graph Languages" />}
                      name="spec.automated_scan_parameters.call_graph_languages"
                      options={languageOptions.callGraphLanguages}
                      placeholder={fm({
                        defaultMessage: 'Use Defaults',
                      })}
                    />
                  </Box>
                </RowStack>
              </Stack>
            </CardContent>

            <CardContent sx={{ borderBottom: `1px solid ${palette.divider}` }}>
              <Stack direction="column" spacing={space.sm} component="fieldset">
                <FormLabel component="legend">
                  <Typography variant="h2" color="text.primary">
                    <FM defaultMessage="Paths" />
                  </Typography>
                </FormLabel>

                <RowStack gap={space.sm} alignItems="flex-start">
                  <Box width={300}>
                    <ControlledTextFieldArray
                      addItemText={<FM defaultMessage="Add Path" />}
                      control={control}
                      label={<FM defaultMessage="Included Paths" />}
                      name="spec.automated_scan_parameters.included_paths"
                    />
                  </Box>

                  <Box width={300}>
                    <ControlledTextFieldArray
                      addItemText={<FM defaultMessage="Add Path" />}
                      control={control}
                      label={<FM defaultMessage="Excluded Paths" />}
                      name="spec.automated_scan_parameters.excluded_paths"
                    />
                  </Box>
                </RowStack>
              </Stack>
            </CardContent>

            <CardContent sx={{ borderBottom: `1px solid ${palette.divider}` }}>
              <Stack direction="column" spacing={space.sm} component="fieldset">
                <FormLabel component="legend">
                  <Typography variant="h2" color="text.primary">
                    <FM defaultMessage="Environment Variables" />
                  </Typography>
                </FormLabel>

                <RowStack gap={space.sm} alignItems="flex-start">
                  <Box width={300}>
                    <ControlledTextFieldArray
                      addItemText={
                        <FM defaultMessage="Add Enviroment Variable" />
                      }
                      control={control}
                      label={
                        <FM defaultMessage="Additional Environment Variables" />
                      }
                      name="spec.automated_scan_parameters.additional_environment_variables"
                    />
                  </Box>
                </RowStack>
              </Stack>
            </CardContent>

            <CardContent>
              <Stack direction="column" spacing={space.md}>
                <Stack
                  direction="column"
                  spacing={space.sm}
                  component="fieldset"
                >
                  <FormLabel component="legend">
                    <Typography variant="h2" color="text.primary">
                      <FM defaultMessage="Bazel Configuration" />
                    </Typography>
                  </FormLabel>

                  <Stack
                    direction="column"
                    spacing={space.md}
                    alignItems="flex-start"
                  >
                    <ControlledCheckboxV2
                      label={
                        <FM defaultMessage="Show internal targets as dependencies" />
                      }
                      name="spec.automated_scan_parameters.bazel_configuration.bazel_show_internal_targets"
                    />

                    <Box width={300}>
                      <ControlledTextField
                        control={control}
                        defaultValue=""
                        label={<FM defaultMessage="Bazel Workspace path" />}
                        name="spec.automated_scan_parameters.bazel_configuration.bazel_workspace_path"
                      />
                    </Box>
                  </Stack>
                </Stack>

                <Stack
                  direction="column"
                  spacing={space.sm}
                  component="fieldset"
                >
                  <FormLabel component="legend">
                    <Typography variant="h3" color="text.primary">
                      <FM defaultMessage="Targets" />
                    </Typography>
                  </FormLabel>

                  <Stack direction="column" spacing={space.sm}>
                    <RowStack gap={space.sm} alignItems="flex-start">
                      <Box width={300}>
                        <ControlledTextFieldArray
                          addItemText={<FM defaultMessage="Add Target" />}
                          control={control}
                          label={<FM defaultMessage="Include Targets" />}
                          name="spec.automated_scan_parameters.bazel_configuration.bazel_include_targets"
                        />
                      </Box>

                      <Box width={300}>
                        <ControlledTextFieldArray
                          addItemText={<FM defaultMessage="Add Target" />}
                          control={control}
                          label={<FM defaultMessage="Exclude Targets" />}
                          name="spec.automated_scan_parameters.bazel_configuration.bazel_exclude_targets"
                        />
                      </Box>
                    </RowStack>

                    <RowStack gap={space.sm}>
                      <Box width={300}>
                        <ControlledTextField
                          control={control}
                          defaultValue=""
                          label={<FM defaultMessage="Targets Query" />}
                          name="spec.automated_scan_parameters.bazel_configuration.bazel_targets_query"
                        />
                      </Box>
                    </RowStack>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </form>
    </FormProvider>
  );
};
