import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { minutesToMilliseconds } from 'date-fns';
import produce from 'immer';
import { cloneDeep, unset as _unset } from 'lodash-es';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1InstallationSpec,
  V1PlatformSource,
  V1ScanState,
} from '@endorlabs/api_client';
import {
  InstallationReadParams,
  InstallationResource,
  QueryInstallationsResponseObject,
  sortParamBuilders,
  useDeleteInstallation,
  useQueryInstallations,
  useUpdateInstallation,
} from '@endorlabs/queries';
import {
  AccordionCard,
  ButtonPrimary,
  ButtonSecondary,
  DataTableActionDropdown,
  EmptyState,
  IconCloud,
  IconCloudOff,
  IconRotateCcw,
  MetadataList,
  MetadataMetric,
  Pip,
  ScanStateLabel,
  useAppNotify,
  useConfirmationDialog,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import {
  EditInstallationDialog,
  InstallationListItem,
  InstallationProjectsTable,
  InstallationProjectsTableRow,
} from '../../domains/Installations';
import { useAuthInfo } from '../../providers';
import {
  getIntegrationsRootPath,
  getProjectPath,
  useFullMatch,
} from '../../routes';
import {
  SOURCE_CONTROL_MANAGERS,
  SourceControlManagerKeys,
  SourceControlManagerOption,
} from './constants';

const REFRESH_INTERVAL = minutesToMilliseconds(1);

const mapToInstallationList = (
  installations: QueryInstallationsResponseObject[],
  currentPlatform: string
): InstallationListItem[] => {
  return installations.map((installation) => {
    const { Project } = installation.meta.references;

    const installationProjects: InstallationProjectsTableRow[] = (
      Project?.list?.objects ?? []
    ).map((project) => {
      const { LatestScanResult } = project.meta.references;

      const scanResults = LatestScanResult?.list?.objects ?? [];
      return {
        uuid: project.uuid,
        namespace: project?.tenant_meta.namespace,
        name: project?.meta.name,
        scanTime: project?.processing_status?.scan_time,
        analyticTime: project?.processing_status?.analytic_time,
        platformSource: project?.spec.platform_source,
        platformType: installation?.spec.platform_type,
        webUrl: project?.spec.git?.web_url,
        scanResults,
      };
    });

    const lastScanTime = installation.processing_status?.scan_time;
    const scanState = installation.processing_status?.scan_state;
    const summary: MetadataMetric[] = [
      {
        label: <FM defaultMessage="Projects" />,
        value: installationProjects.length,
        variant: 'raw',
      },
      {
        label: <FM defaultMessage="URL" />,
        value:
          currentPlatform === SourceControlManagerKeys.Azure
            ? installation.spec.azure_config?.host_url
            : installationProjects.find((p) => !!p.webUrl)?.webUrl,
        variant: 'link',
      },
      {
        label: <FM defaultMessage="Last Scanned" />,
        value: lastScanTime,
        variant: 'date',
      },
      {
        label: <FM defaultMessage="Scan Status" />,
        value: <ScanStateLabel value={scanState} />,
        variant: 'raw',
      },
    ];

    const installationListData: InstallationListItem = {
      uuid: installation.uuid,
      name: installation.meta.name,
      login: installation.spec.login,
      projects: installationProjects,
      summary,
      scanState,
      scanTime: lastScanTime,
      isInvalid: installation.spec.invalid === true,
      //(here) null means implies false
      isMarkedForDeletion: installation.spec.marked_for_deletion === true,
      enabledFeatures: installation.spec.enabled_features,
      spec: installation.spec,
    };
    return installationListData;
  });
};

enum TargetType {
  Organization = 'Organization',
  User = 'User',
}

const installationDeleteLink = (installationSpec: V1InstallationSpec) => {
  const { target_type, external_id, login } = installationSpec;
  if (target_type === TargetType.Organization) {
    // Organization URL format
    return `https://github.com/organizations/${login}/settings/installations/${external_id}`;
  }

  if (target_type === TargetType.User) {
    return `https://github.com/settings/installations/${external_id}`;
  }

  return '';
};
export const SourceControlSettingsPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { platformName },
  } = useFullMatch();

  const sourceControlManager = SOURCE_CONTROL_MANAGERS.find(
    (pm) => pm.key.toLowerCase() === platformName?.toLocaleLowerCase()
  ) as SourceControlManagerOption;

  const qListInstallations = useQueryInstallations(
    tenantName,
    {
      sort: sortParamBuilders.descendingBy('meta.create_time'),
      filter: `spec.platform_type==${sourceControlManager.platformSource}`,
    },
    {
      refetchInterval: REFRESH_INTERVAL,
    }
  );

  const installationResources = useMemo(() => {
    return (qListInstallations.data?.list?.objects ?? []).map((inst) => {
      _unset(cloneDeep(inst), 'meta.references');
      return inst as InstallationResource;
    });
  }, [qListInstallations.data]);

  const installations = useMemo(
    () =>
      mapToInstallationList(
        qListInstallations.data?.list?.objects ?? [],
        platformName
      ),
    [qListInstallations.data?.list?.objects, platformName]
  );

  const addAppNotification = useAppNotify();

  const qDeleteInstallation = useDeleteInstallation();

  const handleDeleteInstallation = (item?: InstallationListItem) => {
    // Find installation from list results
    const installation =
      item &&
      qListInstallations.data?.list?.objects.find((o) => o.uuid === item.uuid);
    if (!installation) return;

    let deleteInstallationPayload: InstallationReadParams = {
      namespace: installation.tenant_meta.namespace,
      uuid: installation.uuid,
    };
    if (platformName === SourceControlManagerKeys.Azure) {
      deleteInstallationPayload = {
        ...deleteInstallationPayload,
        deleteParameters: {
          action: 'markfordeletion',
        },
      };
    }
    qDeleteInstallation.mutate(deleteInstallationPayload, {
      onError: () => {
        addAppNotification({
          id: 'installation:delete:error',
          message: <FM defaultMessage="We've Encountered a Problem" />,
          details: (
            <FM defaultMessage="Failed to delete Installation. Please try again" />
          ),
          severity: 'error',
        });
      },
      onSuccess: () => {
        qListInstallations.refetch();
      },
    });
  };

  const qUpdateInstallation = useUpdateInstallation({
    onError: () => {
      addAppNotification({
        id: 'installation:rescan:error',
        message: (
          <FM defaultMessage="Failed to schedule a rescan. Please try again." />
        ),
        severity: 'error',
      });
    },
    onSuccess: () => {
      qListInstallations.refetch();
      addAppNotification({
        id: 'installation:rescan:success',
        message: <FM defaultMessage="Successfully scheduled a rescan." />,
        severity: 'success',
      });
    },
  });

  const handleRescanOrg = (item?: InstallationListItem) => {
    const installation = qListInstallations.data?.list?.objects.find(
      (i) => i.uuid === item?.uuid
    );
    // TODO: handle possible edge case
    if (!installation) return;

    const updatedInstallation = produce(installation, (draft) => {
      // unset the scan state to trigger a rescan
      draft.processing_status = {
        ...draft.processing_status,
        scan_state: V1ScanState.NotProcessed,
        scan_time: undefined,
      };
    });

    qUpdateInstallation.mutate({
      namespace: updatedInstallation.tenant_meta.namespace,
      resource: updatedInstallation,
      mask: [
        'processing_status.scan_state',
        'processing_status.scan_time',
      ].join(','),
    });
  };

  /**  Licensing and Bundling **/
  const [installationToEdit, setInstallationToEdit] = useState<
    InstallationListItem | undefined
  >(undefined);

  /** End */

  const isLoading = qListInstallations.isLoading;
  const isEmptyState = !isLoading && installations?.length === 0;

  const supportedPlatforms = [V1PlatformSource.Github, V1PlatformSource.Azure];

  // Check if the platform is supported using array includes
  const isPlatformSupported = supportedPlatforms.includes(
    sourceControlManager.platformSource
  );

  const descriptionText = () => {
    switch (platformName) {
      case SourceControlManagerKeys.GitHub:
        return (
          <FM defaultMessage="Projects created through this integration will no longer be automatically scanned." />
        );

      case SourceControlManagerKeys.Azure:
        return (
          <FM defaultMessage="Projects created through this integration will be deleted forever." />
        );

      default:
        break;
    }
  };

  const InstallationDeleteConfirmation =
    useConfirmationDialog<InstallationListItem>({
      confirmText: <FM defaultMessage="Delete" />,
      descriptionText: descriptionText(),
      isDestructive: true,
      onConfirm: handleDeleteInstallation,
      titleText: <FM defaultMessage="Delete this Integration?" />,
    });

  // Function to render the Tooltip content
  const renderInvalidInstallationTooltip = () => (
    <>
      <Typography variant="h4" marginBottom={1}>
        <FM
          defaultMessage="Invalid {platformName} Installation"
          values={{ platformName: sourceControlManager.label }}
        />
      </Typography>
      <Typography variant="body2">
        <FM defaultMessage="Unable to re-sync projects through this installation." />
      </Typography>
    </>
  );

  // Function to render the icon based on installation state
  const renderInstallationIcon = (installation: InstallationListItem) => {
    if (installation.isInvalid) {
      return (
        <Tooltip
          placement="bottom-end"
          title={renderInvalidInstallationTooltip()}
        >
          <IconCloudOff fontSize="inherit" sx={{ color: 'status.failure' }} />
        </Tooltip>
      );
    }

    if (installation.isMarkedForDeletion) {
      return (
        <IconCloudOff fontSize="inherit" sx={{ color: 'status.failure' }} />
      );
    }

    return <IconCloud fontSize="inherit" />;
  };

  // Function to render the complete Stack component
  const renderInstallationStack = (installation: InstallationListItem) => (
    <Stack alignItems="center" direction="row" spacing={3}>
      {renderInstallationIcon(installation)}

      <Typography component="span" variant="inherit">
        {installation.name}
      </Typography>

      {installation.isMarkedForDeletion && (
        <Pip
          color="error"
          label={<FM defaultMessage="Marked for deletion" />}
          size="small"
        />
      )}
    </Stack>
  );

  return (
    <>
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <PageHeader
            action={
              !isEmptyState && (
                <Box display="flex" justifyContent="flex-end">
                  <ButtonPrimary
                    href={getProjectPath({
                      tenantName,
                      uuid: `new/${sourceControlManager.key}`,
                    })}
                  >
                    <FM defaultMessage="Scan More Repositories" />
                  </ButtonPrimary>
                </Box>
              )
            }
            breadcrumbsLinks={[
              {
                label: <FM defaultMessage="Integrations" />,
                url: getIntegrationsRootPath({ tenantName }),
              },
            ]}
            metadata={{ summary: [] }}
            title={sourceControlManager.label}
          />
        </Grid>

        {isEmptyState && (
          <Grid item>
            <EmptyState
              size="large"
              title={
                <FM
                  defaultMessage="You have no {platform} integrations"
                  values={{ platform: platformName }}
                />
              }
            >
              <ButtonPrimary
                href={getProjectPath({
                  tenantName,
                  uuid: `new/${platformName}`,
                })}
              >
                <FM defaultMessage="Create a New Integration" />
              </ButtonPrimary>
            </EmptyState>
          </Grid>
        )}

        {!isEmptyState && (
          <Grid container direction="column" item spacing={6}>
            {installations?.map((installation) => {
              return (
                <Grid item key={installation.uuid}>
                  <AccordionCard
                    disableHeaderTextSelection
                    key={installation.uuid}
                    id={installation.uuid}
                    summaryContent={
                      <MetadataList metrics={installation.summary} />
                    }
                    title={renderInstallationStack(installation)}
                    action={
                      <Stack
                        direction="row"
                        display="flex"
                        justifyContent="flex-end"
                        spacing={4}
                      >
                        <ButtonSecondary
                          disabled={
                            installation.isMarkedForDeletion ||
                            installation.scanState !== V1ScanState.Idle ||
                            installation.isInvalid ||
                            isLoading ||
                            qUpdateInstallation.isLoading
                          }
                          onClick={(evt) => {
                            evt.stopPropagation();
                            handleRescanOrg(installation);
                          }}
                          size="small"
                          startIcon={<IconRotateCcw fontSize="inherit" />}
                        >
                          <FM defaultMessage="Rescan Org" />
                        </ButtonSecondary>

                        {isPlatformSupported && (
                          <DataTableActionDropdown
                            items={[
                              {
                                disabled: installation.isMarkedForDeletion,
                                label: <FM defaultMessage="Edit Integration" />,
                                onClick: (evt) => {
                                  evt.stopPropagation();
                                  setInstallationToEdit(installation);
                                },
                              },
                              {
                                disabled: installation.isMarkedForDeletion,
                                isDestructive: true,
                                label: (
                                  <FM defaultMessage="Delete Integration" />
                                ),
                                onClick: (evt) => {
                                  evt.stopPropagation();

                                  if (
                                    installation.spec?.platform_type ===
                                    V1PlatformSource.Github
                                  ) {
                                    window.location.href =
                                      installationDeleteLink(installation.spec);
                                  } else {
                                    InstallationDeleteConfirmation.openDialog(
                                      installation
                                    );
                                  }
                                },
                              },
                            ]}
                          />
                        )}
                      </Stack>
                    }
                  >
                    <InstallationProjectsTable
                      data={installation.projects}
                      isLoading={isLoading}
                      emptyStateProps={{
                        title: (
                          <FM defaultMessage="No Projects found for this installation" />
                        ),
                      }}
                    />
                  </AccordionCard>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>

      <InstallationDeleteConfirmation.Dialog
        {...InstallationDeleteConfirmation.dialogProps}
      />

      <EditInstallationDialog
        installation={installationResources.find(
          (inst) => inst.uuid === installationToEdit?.uuid
        )}
        onClose={() => setInstallationToEdit(undefined)}
        onSuccess={() => qListInstallations.refetch()}
        open={Boolean(installationToEdit)}
      />
    </>
  );
};
